/* Carousel styles */
.carousel-container {
    margin-bottom: 2rem;
    
  }
  
  .carousel-slide {
    padding: 0 10px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  
  .carousel-slide img {
    width: auto;
    height:  40vh;
    border-radius: 8px;
    transition: all 0.3s ease;
    text-align: center;
  }
  
  .slick-center .carousel-slide img {
    transform: scale(1.2);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .slick-prev, .slick-next {
    z-index: 1;
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  
  .slick-prev:before, .slick-next:before {
    color: #333;
  }
  
  @media (max-width: 768px) {
    .slick-center .carousel-slide img {
      transform: scale(1);
    }
  }
/* ProductCard.css */

.product-card-wrapper {
  margin-bottom: 30px;
}

.product-card-wrapper.product-out-of-stock {
  position: relative;
}

.product-card {
  width: 248px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.438);
  border-radius: 0px;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 25px!important;
}

.product-image-container {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  background-color: #f0f0f0;
  -moz-border-radius: 0px;
  -webkit-border-radius: 24px 24px 0px 0px;
  border-radius: 24px 24px 0px 0px; 
}

.product-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.product-thumbnail {
  z-index: 1;
}

.product-full-image {
  z-index: 2;
  opacity: 0;
  transition: opacity 0.1s ease;
}

.product-full-image.product-image-loaded {
  opacity: 1;
}

.product-no-image {
  object-fit: contain;
  padding: 20px;
  background-color: #8f8e8e;
}

.product-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
  z-index: 3;
  margin-bottom: 44px ;
  border-radius: 25px!important;
}

.product-card-body {
  padding: 15px;
  background-color: #ffffff;
}

.product-card-title {
  margin-bottom: 10px;
  line-height: 1.2;
  white-space: normal;
}

.product-card-text {
  margin: 0;
}
.add-button-producto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background-color: #f0f0f0;
}

.add-button-producto button {
  background: none;
  border: none;
  cursor: pointer;
}

.add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1aa29b;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 10px;
}

.add-button:hover {
  background-color: #177c77;
}
