/* ModalProduct.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.modal-image {
  width: auto;
  height: 40vh;
  border-radius: 8px;
  margin-bottom: 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modal-content h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.modal-content p {
  margin-bottom: 0.5rem;
  color: #666;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #000000;
}

.add-button-producto {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.add-button-producto button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.add-button-producto span {
  margin: 0 1rem;
  font-size: 1.2rem;
}

.add-button-producto img {
  width: 24px;
  height: 24px;
}

.add-button.agregar-button {
  background-color: #1AA29B;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.add-button.agregar-button:hover {
  background-color: #158780;
}

/* Carousel styles */
.carousel-container {
  margin-bottom: 2rem;
}

.carousel-slide {
  padding: 0 10px;
}

.carousel-slide img {
  width: auto;
  height: 40vh;
  border-radius: 8px;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-prev:before,
.slick-next:before {
  color: #333;
}

/* Responsive styles */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    padding: 1.5rem;
  }

  .modal-content h2 {
    font-size: 1.3rem;
  }

  .modal-content p {
    font-size: 0.9rem;
  }
}
