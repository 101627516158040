.GT-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .GT-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 360px;
    max-width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .GT-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #666;
  }
  
  .GT-modal-close:hover {
    color: #000;
  }
  
  .GT-form {
    display: flex;
    flex-direction: column;
  }
  
  .GT-form-title {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .GT-form-group {
    margin-bottom: 15px;
  }
  
  .GT-form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .GT-form-label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .GT-form-submit-container {
    margin-top: 20px;
    text-align: center;
  }
  
  .GT-form-submit {
    background-color: #1aa29b;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    width: auto;
    height: auto;
  }
  
  .GT-form-submit:hover {
    background-color: #158780;
  }