.admin-productos {
    background-image: url('./../images/GRAFICA7.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.admin-product-container {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}


.card-container-wrapper {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
.toolbar-content {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
}



.header-bar {
    background-color: #f5f5f5;
    padding: 10px 20px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
}

.header-text {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.subheader-text {
    font-size: 14px;
    margin: 5px 0 0 0;
    color: #555;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    background-color: #2b28288c;
    padding-top: 20px;
    padding-bottom: 20px;
}
.clear {
    clear: both;
}
