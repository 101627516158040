/* Fondo del sidebar */
.sidebar-bg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
}

/* Estilos del sidebar */
.sidebar {
  background-color: #F1EFEF;
  position: fixed;
  top: 0;
  left: 0;
  width: 332px;
  height: 100%;
  transition: left 0.3s ease-in-out; /* Añadir una transición suave */
  z-index: 1001; /* Asegurarnos de que el sidebar esté por encima del fondo */
}

.sidebar-min {
  left: -300px; /* Ajustar el margen cuando el sidebar está minimizado */
  transition: left 0.3s ease-in-out;
  z-index: 900; /* Asegurarnos de que el sidebar minimizado esté debajo del navbar */
}

/* Estilo del botón de despliegue */
.toggle-btn {
  position: absolute;
  top: 50%; /* Centrar verticalmente */
  right: 2px; /* Posición fuera del sidebar cuando está expandido */
  transform: translateY(-50%); /* Centrar el botón en su propio contenedor */
  background-color: #F1EFEF;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: right 0.3s ease-in-out;
  color: black; /* Cambiar el color del texto a negro */
}

.sidebar-min .toggle-btn {
  right: 2px; /* Ajustar la posición del botón cuando el sidebar está minimizado */
}

/* Estilos adicionales del sidebar */
.sidebar img {
  display: block;
  margin: 10px auto;
  /*height: 11vh;*/
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  padding: 10px;
  cursor: pointer;
  color: black; /* Cambiar el color del texto a negro */
  display: flex;
  align-items: center;
}

.sidebar li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.sidebar li a {
  color: black; /* Cambiar el color del texto a negro */
  text-decoration: none;
  display: flex;
  align-items: center;
}

.sidebar li img.icon {
  margin-right: 10px;
  margin-left: auto;
}
