.login {
  background-image: url('./../images/background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-top: 4%;
}
.formulario-h2{
  font-size: 48px;
  padding-top:50px;
  font-weight: 700;
  line-height: 65.47px;
  color: #574444;
  font-family: "Nunito";
  text-shadow: 0px 4px 4px rgba(0,0,0,0.25);
  
}
.formulario{
  background: rgba(255, 255, 255, 0.87);
  border-radius: 55px;
  width: 497px;
  height: 554px;
  margin-right: 190px;
}

.formulario-input input::placeholder {
  text-align: center;
  font-size: 25px;
}

.formulario-input{
  border-radius: 20px;
  border-width: 1px;
  border-style:solid;
  border-color: black;
  height: 67px;
  width: 404px;
  padding-left: 22px;
  opacity: 80%;
}

.botoncito{
    
    background-color: #00A09A;
    color: white;
    font-size: 30px;
    border-radius: 40px;
    width:404px;
    height:67px;
    border: none;
  }
  .botoncito.btn-registrarse{
    color: rgb(77, 77, 77);
    background-color: white;
    border-width: 1px;
    border-style: solid;
    font-weight: 100;
  }
  #bienvenida{
   max-width: 600px;
   font-size: 50px;
   font-weight:600;
  }
  
.formulario .text-center{
  display: flex;
  justify-content: center;
}
