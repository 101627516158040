.productos-carrito{
    background-image: url('./../images/GRAFICA7.png');
    background-size: cover;
    background-position: center;
    width: auto;
    height: 100vh;
    display: flex;
    padding-top: 50px; /* Añade espacio superior para la barra */
}

.header-bar {
    background-color: #f5f5f5;
    padding: 10px 20px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 20px; /* Añade espacio inferior para separar la barra del contenido */
}
.header-text {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}
.subheader-text {
    font-size: 14px;
    margin: 5px 0 0 0;
    color: #555;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 80vh; /* Establece la altura máxima del contenedor */
    overflow-y: auto; /* Agrega un scroll vertical si el contenido excede la altura máxima */
    min-width: 770px;
    max-width: 1200px;
    position: absolute;
    top: 150px;
    left: 600px;
    gap: 10px;
}

.clear {
    clear: both;
}
.card-wrapper{
    margin-bottom: 30px;
}

.card{
    position: static !important;
    width: 248px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border: rgba(0, 0, 0, 0.438) 1px solid !important;
    border-radius: 0px !important;
}

.card-body {
    padding: 0 !important;
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    height: 61px !important;
    border-top: rgba(0, 0, 0, 0.582) 2px solid;
}

.card-title {
    font-size: 18px;
    text-align: left; /* Alinear el texto a la izquierda */
    margin: 0 !important;

}

.card-img-top {
    width: 100%;
    height: auto;
    background-color: white; /* Fondo blanco para la parte superior de la tarjeta */
}

.card-line {
    width: 100%;
    margin-top: 10px; /* Espacio entre la imagen y la línea */
    margin-bottom: 10px; /* Espacio entre la línea y el nombre/cantidad */
}

.card-text {
    background-color: #cfcfcf;
    border-radius: 5px;
    padding: 0px 10px;
    color: #000000;
    font-weight: 400;
    display: flex;
    justify-content: center;
}
.card-text p{
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.add-button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 39px;
    background-color: #1aa29b; /* Verde */
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transición suave al pasar el ratón */
    margin-top: 5px;
    border-radius: 10px;
}

.add-button:hover {
    background-color: #158780; /* Cambia el color al pasar el ratón */
}
.add-button-producto{
    display: flex;
    background-color: #cfcfcf;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    margin-top: 10px;
}
.add-button-producto button{
    border-radius: 10px;
    padding: 10px;
    width: 39px;
    height: 39px;
}

.volver-button{
    margin-top: 80vh;
    margin-left: 20vh;
    text-align: center;
    align-items: center;
    padding: 10px;
}