.landingpanol{
    background-image: url('./../images/lagunasbackground.png');
    background-size: cover;
    background-position: center;
    width: auto;
    height: 100vh;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

