.card-wrapper.out-of-stock {
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
}

.card-wrapper.out-of-stock .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;
    z-index: 1;
    
}

.card-wrapper.out-of-stock .card img {
    filter: grayscale(100%);
}

.card-wrapper.out-of-stock .card-body {
    opacity: 0.5;
}

.card-wrapper {
    margin-bottom: 30px;
    
    
}

.card {
    border-radius: 25px!important;
}

.card-body {
    display: flex;
    justify-content: space-between;
    height: 100%;
    border:#cfcfcf;
}

.card-title {
    margin-bottom: auto;
    line-height: 1.2;
    white-space: normal; /* Allow title to wrap */
    padding-left: 10px;
}
.card-title2 {
    margin-bottom: auto;
    line-height: 1.2;
    white-space: normal; /* Allow title to wrap */
    padding-left: 10px;
    font-size: medium;
    color:gray;
    outline: #cfcfcf;
}

.card-footer {
    display: flex;
}


.card-line {
    width: 100%;
    margin: 10px 0;
}

.card-text {
    margin: 0;
    flex-shrink: 0;
    white-space: nowrap; /* Prevent the quantity text from wrapping */
}

.add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1aa29b;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-button:hover {
    background-color: #177c77;
}

.icon-buttons {
    display: flex;    
    align-items: center;
    background-color: #cfcfcf;
    border-radius: 25px;
    padding: 5px;
    margin-right: 10px;

}

.edit-icon {
    color: #1aa29b;  /* Pencil icon color */
    cursor: pointer;
    transition: color 0.3s ease;
}

.edit-icon:hover {
    color: #177c77;;  /* Darker blue when hovered */
}

.trash-icon {
    color: red;
    cursor: pointer;
    transition: color 0.3s ease;
}

.trash-icon:hover {
    color: rgb(136, 0, 0);
}

.image-container {
    width: 100%;
    height: 300px; /* Adjust this value as needed */
    position: relative;
    overflow: hidden;
    background-color: #8f8e8e;/* Light grey background */
    -moz-border-radius: 0px;
    -webkit-border-radius: 24px 24px 0px 0px;
    border-radius: 24px 24px 0px 0px; 
}

.card-img-top {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.thumbnail {
    z-index: 1;
}

.full-image {
    z-index: 2;
    opacity: 0;
}

.full-image.loaded {
    opacity: 1;
}

.no-image-icon {
    width: 50%; /* Adjust as needed */
    height: auto;
    max-height: 100%;
    position: relative;
    z-index: 3;
    background-color: #8f8e8e;
}