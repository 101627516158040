form {
    max-width: 699px;
    height: 363px;
    border-radius: 40px;
    margin: 0 auto;
}

input {
    border-radius: 20px;
    border-style: solid !important;
    border-width: 1px !important;
    height: 53.48px;
    width: 593px;
    box-shadow: inset;
}

input::placeholder {
    font-size: 20px;
    font-weight: 200;
    color: rgb(111, 111, 111);

}

form h3 {
    padding-top: 30px;
    padding-bottom: 20px;
    opacity: 60%;
}

.button {
    color: #00A09A;
    background-color: white;
    border-style: solid !important;
    border-color: #00A09A !important;
    width: 118px;
    height: 44px;
    border-radius: 30px;
    font-weight: 100;
    margin-left: 55px;
    margin-right: 55px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
}


.error-message {
    font-size: 18px;
    padding: 3px;
    color: red;
    display: none;
}

input:invalid[focused="true"] ~ .error-message {
    display: block;
}

.content:invalid[focused="true"] ~ .error-message {
    display: block;
}