.bg-confirmar {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(199, 199, 199, 0.753);
    z-index: 1200;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirmar {

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 40px;
    width: 543px;
    height: 210px;
    text-align: center;

}

.confirmar_options {
    width: 334px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.confirmar_options h1{
    font-size: 50px;
    margin-bottom: 10px;
}

.confirmar_buttons {
    display: flex !important;
    justify-content: space-between;
}

.confirmar_buttons button {
    font-weight: 500;
    color: #00A09A;
    border: 1px solid #00A09A;
    border-radius: 30px;
    width: 118px;
    height: 44px;
    background-color: white;
    transition: background-color 0.3s ease;
}
.confirmar_buttons button:hover {
    background-color: rgba(187, 187, 187, 0.541);

}