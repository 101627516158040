select {
    pointer-events: none;
    background: none !important;
    color: white !important;
    font-size:50px !important;
    text-transform: uppercase !important;
    position: relative;
}



/*SELECTOR DE RANGO DE DIAS*/
.rdrDateRangePickerWrapper.dateRange{
    border: none !important;
}
.rdrDateRangePickerWrapper.dateRange:hover{
    border: none !important;
}
.rdrDayHovered{
    border: none !important;
}
.rdrDefinedRangesWrapper{
    display: none;
}
/*AÑO ACTUAL*/
.rdrYearPicker {
    display: none;
}

/*CONTENEDOR DE CALENDARIO*/
.rdrCalendarWrapper.rdrDateRangeWrapper {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50px;
    height: 480px;
}


/*CONTENEDOR TITULO*/
.rdrMonthPicker{
    background-color: #1AA29B;
    border-radius:40px;
    position: relative;
    bottom: 30px;
}

.rdrMonthAndYearPickers select {
    padding: 10px 30px 10px 30px !important;
    text-align: center !important;
}
/* CONTENEDOR DEL MES*/
.rdrMonth{
    width: 645px !important;
    padding: 0 !important;

}
/*DIAS DE LA SEMANA*/
.rdrWeekDay{
    font-weight:500 !important;
    color: black !important;
    text-transform: uppercase !important;
}

/*PRIMERO, MEDIO/S, ULTIMO NODO AL SELECCIONAR*/
.rdrEndEdge,.rdrInRange,.rdrStartEdge {
    color: rgba(26,162,155, 0.7) !important;
    border: none;
}


/*SUBRAYADO EN EL DÍA ACTUAL*/
.rdrDayToday .rdrDayNumber span::after{
    background-color: transparent !important;
}


.rdrDays button{
    padding-top: 56px;
}

.rdrNextPrevButton.rdrNextButton{
    background-image: url(../images/Vector\ 13.png) ;
    margin-right: 24px;
    margin-left: 0;
    background-color: transparent;
    background-position: center; 
    background-repeat: no-repeat;
    width: auto;
    height: auto;
}


.rdrNextPrevButton.rdrNextButton i {
    visibility: hidden;
    width: 40px;
    height: 40px;
}

.rdrNextPrevButton.rdrPprevButton{
    transform: rotate(180deg);
    background-image: url(../images/Vector\ 13.png) ;
    margin-left: 24px;
    margin-right: 0px;
    background-color: transparent;
    background-position: center; 
    background-repeat: no-repeat;
    width: 47px;
    height: auto;
}

.rdrNextPrevButton.rdrPprevButton i {
    visibility: hidden;
    width: 40px;
    height: 40px;
}

.rdrNextPrevButton.rdrPprevButton:hover {
    transform: rotate(180deg);
    background-image: url(../images/Vector\ 13.png) ;
    margin-left: 24px;
    margin-right: 0;
    background-color: transparent;
    background-position: center; 
    background-repeat: no-repeat;
    width: 47px;
    height: auto;
}

.rdrNextPrevButton.rdrNextButton:hover{
    background-image: url(../images/Vector\ 13.png) ;
    margin-right: 24px;
    margin-left: 0;
    background-color: transparent;
    background-position: center; 
    background-repeat: no-repeat;
    width: auto;
    height: auto;
}