.request {
    background-image: url('../images/solicitud-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100vh;
    font-weight: 400;
    color: #574444;
    
}

.request input {
    padding: 0 14px 0 14px;
    text-align: center;
    box-shadow: 0px 4px 4px 0px #00000040;
    border: 1px solid gray;
    min-width: 90%;
    max-width: 90%;
}

.bg-white {
    width: 699px;
    height: 350px;
    border-radius: 70px;
}

a .button {
    margin: 0;
}
.container-buttons {
    padding: 0 36px 0 36px;
}

a .button:hover {
    color: #008f8a;
    background-color: white;
    border: 3px solid !important;
    border-color: #008f8a !important;
}

