.BackgroundImage{
    background-image: url('./../images/Catedral_Demarmol.png');
    background-size: cover;
    background-position: center;
    min-height: 100vh; /* Asegura que cubra toda la altura de la ventana */
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
