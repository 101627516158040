.GT-container {
    background-image: url('./../images/GRAFICA7.png');
    width: 100vw;
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 50px;
  }
  
  .GT-inner-container {
    margin-top: 10vh;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 10px;
    width: 80%;
    max-width: 800px;
    max-height: 80vh; /* Set a maximum height */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds max-height */
  }
  
  .GT-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .GT-subtitle {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #444;
  }
  
  .GT-loading, .GT-error {
    margin: 10px 0;
    font-style: italic;
  }
  
  .GT-error {
    color: #ff0000;
  }
  
  .GT-create-button {
    background-color: #1aa29b;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    width: auto;
    height: auto;
    margin: auto;
    width: 50%;
  }
  
  .GT-create-button:hover {
    background-color: #158780;
  }
  
  .GT-taller-list {
    list-style-type: none;
    padding: 0;
    max-height: 50vh; /* Set a maximum height for the list */
    overflow-y: auto; /* Enable vertical scrolling if list exceeds max-height */
  }
  
  .GT-taller-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .GT-taller-info {
    flex-grow: 1;
  }
  
  .GT-taller-actions {
    display: flex;
    gap: 10px;
  }
  
  .GT-edit-button, .GT-delete-button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .GT-edit-button {
    background-color: #2196F3;
    color: white;
    width: auto;
  }
  
  .GT-edit-button:hover {
    background-color: #0b7dda;
  }
  
  .GT-delete-button {
    background-color: #f44336;
    color: white;
    width: auto;
  }
  
  .GT-delete-button:hover {
    background-color: #da190b;
  }