.GU-container {
  background-image: url('./../images/GRAFICA7.png');
  width: 100vw;
  height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
}

.GU-inner-container {
  margin-top: 10vh;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
}

.GU-title {
  font-size: 34px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.GU-subtitle {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #444;
}

.GU-loading, .GU-error {
  margin: 10px 0;
  font-style: italic;
}

.GU-error {
  color: #ff0000;
}

.GU-create-button {
  background-color: #1aa29b;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  width: 50%;
  margin: auto;
}

.GU-create-button:hover {
  background-color: #158780;
}

.GU-user-list {
  list-style-type: none;
  padding: 0;
}

.GU-user-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
}

.GU-user-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.GU-user-info {
  flex-grow: 1;
}

.GU-user-actions-and-tags {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.GU-user-tags {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}

.GU-tag {
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: bold;
}

.GU-tag-admin {
  background-color: #4CAF50;
  color: white;
}

.GU-tag-status {
  background-color: #2196F3;
  color: white;
}

.GU-tag-inactive {
  background-color: #9E9E9E;
  color: white;
}

.GU-user-actions {
  display: flex;
  gap: 10px;
}

.GU-edit-button, .GU-delete-button {
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  width: auto;
  transition: background-color 0.3s;
}

.GU-edit-button {
  background-color: #2196F3;
  color: white;
}

.GU-edit-button:hover {
  background-color: #0b7dda;
}

.GU-delete-button {
  background-color: #f44336;
  color: white;
}

.GU-delete-button:hover {
  background-color: #da190b;
}