.checklist_bg{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1100;
    top:0;
    left: 0;
}
.checklist{
    background-color: rgb(255, 255, 255);
    width: 699px;
    height: 699px;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1100;
    top: 10%;
    left: 30%;
}
.checklist h1{
    padding-bottom: 50px;
    color: rgb(105, 105, 105);
}
.checklist_container{
    width: 593px;
}
.checklist p{
    height: 53.48px;
    border: solid 1px grey;
    border-radius: 20px;
    margin-bottom: 15px !important;
    padding-left: 30px;
    line-height: 50px;
    color: grey;
    font-size: 20px;
    font-weight: 200;

}
.checklist_button{
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    
}

  
.checklist button{
    width: 108px;
    height: auto;
    background-color: white;
    color: #00A09A;
    border: 1px solid #00A09A;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 200;
}
.checklist button:hover{
    background-color: rgba(187, 187, 187, 0.541);
  }