.dropdown{
    display: flex;
    justify-content: center;
}

.dropdown-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 53px;
    padding: 1rem;
    /* border-radius: 0.5rem; */
    /* box-shadow: rgb(149, 157, 165, 0.5) 0px 8px 24px; */
    cursor: pointer;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border: 1px solid grey;
}

.toggle-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
}

.button-open {
    border: rgb(147, 197, 253) 3px solid;
}