.SI-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    z-index: 40;
    border-radius: 32px;
    max-height: 80vh;
    width: 575px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 0px;
    margin-top: 10vh;
}
.SI-h2{
background-color: #1AA29B !important;
color: white !important;
font-size: xxx-large !important;
}
.item-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;
    background-color: #ffffff;
    font-weight: bold;
}

.item-table th {
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: sticky;
    top: 0;
    z-index: 2;
}

.bg-white.text-center.item-table tbody tr td p {
    padding-left: 30px;
    font-weight: light;
}

.bg-white.text-center.item-table tbody tr div td img {
    margin-left: 40px;
}

.bg-white.text-center.item-table tbody tr {
    margin-bottom: 20px;
}

.Theader th {
    z-index: 3;
    color: white;
    background-color: #1AA29B;
    padding: 20px;
    position: relative;
}

.toggle-button {
    position: absolute;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

.toggle-button:hover {
    color: red;
}

.bg-white.text-center img {
    max-height: 100px;
}

.item-table td .unit-text {
    text-align: center;
}

.producto-equipo {
    font-weight: lighter;
    font-size: 18px;
}

.unit-text {
    display: inline-block;
    border: none;
    border-radius: 10px;
    background-color: #E7E8E8;
    width: 80px;
    height: 30px;
    font-size: 17px;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
}

.table-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 80vh;
    background-color: #ffffff;
    border-radius: 32px;
    width: 100%;
}
.table-header {
    background-color: #1AA29B;
    color: white;
    padding: 20px;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    position: relative;
}
.table-body {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
}
.table-body table {
    width: 100%;
}

.table-footer {
    padding: 20px;
    text-align: center;
}

.item-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 20px;
}
.item-table thead {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.item-table th {
    padding: 10px;
    text-align: left;
    font-weight: bold;
}

.table-body::-webkit-scrollbar {
    width: 12px;
}

.table-body::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.table-body::-webkit-scrollbar-thumb {
    background: #1AA29B;
    border-radius: 10px;
}

.table-body::-webkit-scrollbar-thumb:hover {
    background: #177c77;
}
.Theader {
    position: sticky;
    top: 0;
    z-index: 2;
}

.Theader tr {
    height: 100px;
}

.bg-white.text-center.item-table tbody tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    padding-bottom: 20px;
    margin-bottom: 40px;
}

.overlayShowItems {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark semi-transparent background */
    z-index: 30;
    font-family: 'Nunito', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.show-item-cantidad {
    border: none !important;
    position: relative;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.bg-white.text-center.item-table tbody tr div {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.show-checklist-button{
    width: auto;
    margin:auto;
    padding: 30px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.show-checklist-button:hover{
    background-color: #158780;
}