.nuevo-producto-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden; /* Ensure no horizontal scrollbar */
    z-index: 1000;
    font-family: 'Nunito', sans-serif;
}

.nuevo-producto-header {
    background-color: #1AA29B;
    padding: 20px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nuevo-producto-header h2 {
    color: white;
    margin: 0;
    flex-grow: 1;
    text-align: center;
}

.nuevo-producto-close {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: white;
}

.nuevo-producto-close:hover {
    color: #e0e0e0;
}

.nuevo-producto-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    max-height: calc(90vh - 60px);
}

.nuevo-producto-form-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.nuevo-producto-form-group label {
    width: 140px; /* Adjusted width for labels to accommodate longer text */
    margin-right: 10px;
    font-size: 16px;
    color: #333;
    text-align: right; /* Align text to the right */
}

.nuevo-producto-form-group input,
.nuevo-producto-form-group textarea {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    height: auto !important;
    width: 340px !important;
}

.nuevo-producto-form-group textarea {
    min-height: 100px;
    resize: vertical;
}

.nuevo-producto-form .submitButton {
    background-color: #1AA29B;
    color: white;
    border: none;
    padding: 25px 100px; /* Increased vertical padding and reduced horizontal padding */
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    transition: background-color 0.3s ease;
    position: sticky;
    bottom: 20px;
    align-self: center;
}

.nuevo-producto-form .submitButton:hover {
    background-color: #158780;
}

.image-preview {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-preview img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.image-preview button {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    color: #333;
}

.image-preview button:hover {
    background-color: rgba(255, 255, 255, 1);
}



.image-upload-label input[type="file"] {
    display: none;
}




.nuevo-producto-modal::-webkit-scrollbar {
    width: 12px;
}

.nuevo-producto-modal::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.nuevo-producto-modal::-webkit-scrollbar-thumb {
    background: #1AA29B;
    border-radius: 10px;
}

.nuevo-producto-modal::-webkit-scrollbar-thumb:hover {
    background: #177c77;
}

@media (max-width: 600px) {
    .nuevo-producto-modal {
        width: 95%;
        padding: 0;
    }

    .nuevo-producto-form {
        padding: 20px;
    }

    .nuevo-producto-form-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .nuevo-producto-form-group label {
        width: auto;
        text-align: left;
        margin-bottom: 5px;
    }
}
.image-upload-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
    width: 100%;
}
.image-preview-scroll {
    width: 100%;
    overflow-x: hidden; /* Hide horizontal scroll if there is any */
    overflow-y: auto; /* Allow vertical scrolling for overflow */
    padding: 10px 0;
    max-height: 250px; /* Adjust as needed */
}


.image-preview-item {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #f0f0f0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.image-preview-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}


.remove-image-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}
.remove-image-btn:hover {
    background: rgba(0, 0, 0, 0.7);
}


.image-upload-label {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    border: 2px dashed #ccc;
    transition: background-color 0.3s ease;
}

.image-upload-label:hover {
    background-color: #d0d0d0;
}

.image-upload-label .plus-icon {
    font-size: 36px;
    color: #666;
}

#image-upload {
    display: none;
}
.image-processing-progress {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.image-processing-progress progress {
    flex-grow: 1;
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
}

.image-processing-progress span {
    font-size: 14px;
    color: #666;
}
.image-preview::-webkit-scrollbar {
    width: 8px;
}

.image-preview::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.image-preview::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.image-preview::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.input{
    height: auto !important;
}