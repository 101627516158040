.GU-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* High z-index to ensure it's on top */
}

.GU-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 360px;
  max-width: 90%;
  max-height: 90vh; /* Limit height and enable scrolling if needed */
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.GU-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #666;
}

.GU-modal-close:hover {
  color: #000;
}

.GU-form {
  display: flex;
  flex-direction: column;
}

.GU-form-title {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.GU-form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
}

.GU-form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.GU-form-checkbox-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 10vh;
  height: 5vh;
  margin: auto;
}

.GU-form-checkbox {
  margin-right: 5px;
}

.GU-form-submit {
  margin-top: 20px;
  background-color: #1aa29b;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  width: auto;
  height: auto;
}

.GU-form-submit:hover {
  background-color: #158780;
}