.solicitudes {
    margin: 30px auto;
    max-height: 585px;
    overflow-y: auto;
    border-radius: 30px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    background-color: white;
    width: 90%;
    padding: 20px;
}
.panol-check-btn{
    width: auto!important;
    display: block!important;
    margin: auto!important;
}

.tabla-solicitudes {
    background-color: white;
    width: 100%;
    border-radius: 0 0 30px 30px;
    text-align: center;
    margin: 0 auto;
    border-collapse: separate; /* Changed from collapse to separate */
    border-spacing: 0; /* Ensure no space between cells */
}

.tabla-cabecera-solicitudes {
    background-color: white;
    width: 100%;
    position: sticky; /* Make the header sticky */
    top: 0; /* Stick to the top of the container */
    z-index: 1; /* Ensure it stays on top of other content */
}

.tabla-cabecera-solicitudes-titulos {
    font-weight: 500;
    background-color: #1AA29B;
    color: white;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 20px;
    padding: 10px 0;
    box-sizing: border-box;
}

.tabla-cabecera-solicitudes-titulos th {
    padding: 10px 20px;
    border-radius: 20px;
    flex: 1;
    text-align: center;
}

.tabla-cuerpo-solicitudes {
    width: 100%;
    margin: 0 auto;
    background-color: white;
}

.tabla-cuerpo-solicitudes tr {
    height: 70px;
    border-bottom: 1px solid #ccc; /* Add bottom border */
    width: 100%;
    display: flex; /* Flex display */
    justify-content: space-around; /* Even spacing */
    align-items: center; /* Center items vertically */
    box-sizing: border-box; /* Include padding in width/height calculations */
}

.tabla-cuerpo-solicitudes td {
    flex: 1; /* Allow columns to grow/shrink evenly */
    text-align: center; /* Center text within columns */
    border: none; /* Remove all borders */
}

.solicitudes h2 {
    background-color: white;
    color: #1AA29B;
    margin: 0;
    height: 102px;
    border-radius: 30px 30px 0 0;
    padding-top: 30px;
    text-align: center;
    padding-left: 0;
}


#Ojito{
    cursor:pointer;
}

.img-estado {
    padding-left: 12px;
}

.solicitudes::-webkit-scrollbar {
    display: none; /* Oculta la barra de desplazamiento en Safari */
}

.filtros-form {
    color: black !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-items: end;
    width: 100% !important;
    gap: 1rem;
    max-width: max-content !important;
    label {
        display: flex;
        flex-direction: column;
    }
}

.filtros-form-select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid black;
    font-size: medium !important;
    color: black !important;
    pointer-events: all !important;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    background: #f9fafb !important;
}

.filtros-form-input{
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    background: #f9fafb !important;
    color: #6b7280;
    padding: 0.5rem 0.75rem;
    font-size: medium !important;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
    height: auto !important;
    max-width: 15rem !important;
    max-height: 2.3rem !important;
}

.paginator {
    display: flex !important;
    justify-content: center;
    
    margin-top: 1rem;
}

.paginator-page-selector {
    display: flex;
    justify-content: center;
}

.paginator-button {
    border-radius: 0.375rem;
    color: #e5e7eb;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    height: auto !important;
    margin-left: 0px!important;
    margin-right: 0px!important;

}

.paginator-input {
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    background-color: #f9fafb;
    color: #6b7280;
    padding: 0.5rem 0.75rem;
    font-size: medium !important;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
    height: auto !important;

    margin-left: 1rem!important;
    margin-right: 1rem!important;
}

textarea{
    resize: none!important;
    height: 20vh;
    width: 30vw;
    margin: auto;
}

.sortable {
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover {
        background-color: #1d958e;
    }
}