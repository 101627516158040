.cart {
  background: white;
  display: none;
  padding: 32px;
  position: fixed;
  right: 0px;
  top: 70px;
  width: 463px;
  border-radius: 12px;
  z-index: 600;
  text-align: center;
}

.cart img {
  width: 20%;
}

.container-productos ul {
  list-style-type: none;
  padding: 0;
  overflow-y: scroll;
  max-height: 600px;
}

.container-productos li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart li {
  border-bottom: 1px solid #444;
  padding-bottom: 16px;
}

.cart footer {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}


.cart-button {
  align-items: center;
  color: white;
  border-radius: 9999px;
  cursor: pointer;
  display: flex;
  height: 42px;
  justify-content: center;
  padding: 4px;
  position: absolute;
  right: 70px;
  top: 10px;
  transition: all .3s ease;
  width: 46px;
  z-index: 600;
}
.cart-item-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: white; 
  color: #00A09A;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.cart-button:hover {
  scale: 1.1;
}

.cart-button~input:checked~.cart {
  height: 100%;
  display: block;
}

.button-siguiente {
  position: absolute;
  color: #00A09A;
  background-color: white;
  border-style: solid !important;
  border-color: #00A09A !important;
  width: 118px;
  height: auto;
  border-radius: 30px;
  margin-left: 55px;
  margin-right: 55px;
  bottom: 10%;
  left: 250px;
}
.button-siguiente:hover{
  background-color: rgba(187, 187, 187, 0.541);
}

.icono-cerrar {
  position: absolute;
  right: 20px;
  top: 38px;
}

h2 {
  padding-left: 24px;
}

.container-productos {
  display: flex;
  flex-direction: row;
  padding-top: 30px;

}

.item-counter {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 24px;
  margin-left: 26px;
}

button {
  background-color: #26a69a;
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
}

button:focus {
  outline: none;
}

.delete-button {
  background-color: #26a69a;
}

.minus-button, .plus-button {
  font-size: 30px;
}

.minus-button {
  margin-left: 15px;
}

.item-count {
  font-size: 16px;
  color: black;

  margin: 0 26px;
}
.botones-carrito {
  border-radius: 12px;
}

.icono-cerrar {
  position: absolute;
  right: 20px;
  top: 38px;
  cursor: pointer; /* Añadido para indicar que es clickable */
}

/* Reglas de estilo para el icono al pasar el ratón */
.icono-cerrar:hover {
  filter: brightness(110%); /* Cambia el color a un tono más claro */
  border: 2px solid black; /* Añade bordes negros */
}

.lista-productos-carrito p{
  text-align: start;
  padding-left: 50px;
  padding-bottom: 15px;
  font-size: 18px;
}
.button-siguiente:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}