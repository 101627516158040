.navbar {
	background-color: #1AA29B;
	min-height: 69px;
	box-shadow: 0px 5px 20px -3px rgba(0, 0, 0, 0.75);
	position: fixed !important;
	width: 100%;
	z-index: 1000;

	/* Asegura que el navbar esté siempre encima */
}

	span {
		font-size: 25px;
	}



	.text-white {
		margin-right: 10px;
		/* Ajusta el margen hacia la izquierda */
	}

	.img-logo {
		max-width: 31px;
		max-height: 31px;
		margin-right: 43px;
		/* Ajusta el margen hacia la izquierda */
	}

	.search-container {
		position: relative;
	}

	.lupa-background {
		position: absolute;
		top: 49%;
		right: 4px;
		transform: translateY(-50%);
		width: 63px;
		height: 36px;
		z-index: 1;
	}

	.lupa-icon {
		position: absolute;
		top: 49%;
		right: 23px;
		transform: translateY(-50%);
		width: 23px;
		height: 23px;
		z-index: 2;
	}

	.search-input {
		padding-right: 30px;
		/* Para dejar espacio para el icono de la lupa */
		border-radius: 19px;
		border-style: solid !important;
		border-width: 1px !important;
		height: 38.48px;
		width: 612px;
		margin-left: 50px;
		/* Ajusta el margen izquierdo */
	}

	.search-input::placeholder {
		color: #858585;
		/* Color gris */
		font-weight: bold;
		/* Texto más grueso */
		text-align: center;
		/* Centrado */
		margin-right: 50%;
		/* Ligeramente más centrado hacia la derecha */
	}

	.autocomplete-suggestions {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		max-height: 200px;
		/* Limita la altura máxima de la lista */
		overflow-y: auto;
		/* Añade barra de desplazamiento vertical si es necesario */
		background-color: white;
		border: 1px solid #ccc;
		border-top: none;
		/* Elimina el borde superior para que se vea como una lista continua */
		z-index: 1000;
		/* Asegura que la lista esté sobre otros elementos */
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		border-radius: 15px;
		/* Bordes completamente redondeados */
	}

	.suggestion-item {
		padding: 10px;
		cursor: pointer;
	}

	.suggestion-item:hover {
		background-color: #f0f0f0;
	}

	.informacion_navbar {
		margin-right: 70px !important;
	}

