.sidebar-bg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.sidebar {
  background-color: #F1EFEF;
  position: fixed;
  /* z-index: 100; */
  top: 0;
  width: 332px;
  height: 100%;
}

.sidebar-img {
  display: block;
  margin: 10px auto;
  /*height: 11vh;*/
}

.sidebar-columnas {
  display: flex;
  justify-content: space-between;
}

.sidebar_list {
  list-style: none;
  padding: 3px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.sidebar_item_list {
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  background-color: rgba(217, 217, 217, 0.3);
  height: 104px;
  display: flex;
}

.sidebar_item_list:hover {
  background-color: rgba(132, 132, 132, 0.3);
  text-decoration: none;
}

.sidebar_item_link_link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.sidebar_item_link_link:hover {
  text-decoration: none;
  color: black;
}

.sidebar_icon {
  max-width: 40px;
  max-height: 40px;
}

.sidebar_item_text {
  padding-left: 15px;
}

.sidebar-min {
  left: -330px;
  position: absolute;
  z-index: 10;
  background-color: rgba(255, 255, 255);
}

.sidebar-btn {
  top: 0;
  left: 0;
  height: 100% !important;
  background: white;
  border: none;
  color: black;
  font-size: 24px;
  cursor: pointer;
  width: 39px;
  position: absolute;
  z-index: 300;
}
