/* Overlay.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Darken the background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.overlay-container {
    position: relative;
    background-color: white;
    width: 543px;
    height: 210px;
    border-radius: 70px;
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.message {
    margin-top: 20px; /* Adjust as needed */
    font-size: 1.2rem; /* Adjust as needed */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    background: transparent;
    color: black;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.close-button:hover {
    color: red;
}

.confirmation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.confirmation-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #00A09A;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.confirmation-buttons button:hover {
    background-color: #007a66;
}
