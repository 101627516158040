/* Existing Styles */
.admin-product-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    z-index: 1000;
    font-family: 'Nunito', sans-serif;
}

.admin-product-header {
    background-color: #1AA29B;
    padding: 20px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-product-header h2 {
    color: white;
    margin: 0;
    flex-grow: 1;
    text-align: center;
}

.admin-product-close {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: white;
}

.admin-product-close:hover {
    color: #e0e0e0;
}

.admin-product-form {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 30px 20px;
}

.admin-product-form-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.admin-product-form-group label {
    width: 100px;
    margin-right: 10px;
    font-size: 16px;
    color: #333;
    text-align: right;
}

.admin-product-form-group input,
.admin-product-form-group textarea {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
    height: auto !important;
}

.admin-product-form-group textarea {
    min-height: 80px;
    resize: vertical;
}

.admin-product-form .submitButton {
    background-color: #1AA29B;
    color: white;
    border: none;
    padding: 25px 90px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    transition: background-color 0.3s ease;
    align-self: center;
}

.admin-product-form .submitButton:hover {
    background-color: #158780;
}

.admin-product-modal::-webkit-scrollbar {
    width: 12px;
}

.admin-product-modal::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.admin-product-modal::-webkit-scrollbar-thumb {
    background: #1AA29B;
    border-radius: 10px;
}

.admin-product-modal::-webkit-scrollbar-thumb:hover {
    background: #177c77;
}

@media (max-width: 600px) {
    .admin-product-modal {
        width: 95%;
        padding: 0;
    }

    .admin-product-form {
        padding: 20px;
    }

    .admin-product-form-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .admin-product-form-group label {
        width: auto;
        text-align: left;
        margin-bottom: 5px;
    }
}

/* New Styles for Image Upload and Previews */
.image-upload-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.image-preview {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-preview img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.image-preview button {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    color: #333;
}

.image-preview button:hover {
    background-color: rgba(255, 255, 255, 1);
}

.image-upload-label {
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 1px dashed #ccc;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    color: #999;
    background-color: #f5f5f5;
}

.image-upload-label input[type="file"] {
    display: none;
}

.image-upload-label:hover {
    background-color: #e9e9e9;
}
