.add-product-form {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 40px;
    border-radius: 10px;
    width: 95%;
    height: 80vh;
    max-width: 90%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: auto;
}

.add-product-form h2 {
    background-color: #25b59e;
    color: white;
    padding: 15px;
    border-radius: 10px 10px 0 0;
    margin: -40px -40px 20px -40px;
    text-align: left;
}

.form-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.form-fields, .form-image {
    display: flex;
    flex-direction: column;
}

.form-fields {
    flex: 3;
    margin-right: 20px;
}

.form-image {
    max-width: 100%; /* Mantenemos el máximo ancho actual */
    max-height: 100%; /* Ajusta este valor según sea necesario para controlar la expansión vertical */
    
}

.formulario-grupo {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-full-width {
    width: calc(100% - 40px); /* Adjust this value to control the width */
}

.formulario-grupo input[type="text"],
.formulario-grupo input[type="number"],
.formulario-grupo input[type="file"] {
    padding: 27px;
    border: 1px solid #ccc;
    border-radius: 35px;
    font-size: 18px;
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.formulario-grupo input::placeholder {
    color: rgba(0, 0, 0, 0.6); /* Color del texto del placeholder */
}

.image-preview {
    margin-top: 10px;
    width: 100%;
    height: auto;
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.formulario-grupo label {
    margin-bottom: 5px;
    font-weight: bold;
}

.submit-button {
    background-color: #25b59e;
    color: white;
    padding: 40px;
    border: none;
    border-radius: 32px;
    font-size: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 408px;
    align-self: center;
    margin-top: 91%;
}

.submit-button:hover {
    background-color: #1aa29b;

}
input {
    border-radius: 20px;
    border-style: solid !important;
    border-width: 1px !important;
    height: 90.48px;
    width: 593px;
    box-shadow: inset;
}
