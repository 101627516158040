.categorias {
    display: inline-flex;
    gap: 3px;
    position: fixed;
    left: 50px;
    top: 100px;
    z-index: 500;
}

.categorias__table, .sub_categorias__table {
    background-color: #ffffff;
    border: #00000080 solid 1px;
    border-radius: 10px;
}

.categorias__table {
    width: 436px;
    height: 630px;
    overflow-y: auto;
    user-select: none;
}

.categorias__table table {
    width: 100%;
}

.categorias__thead {
    border-bottom: black 1px solid;
    width: 100% !important;
}

.categorias__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    font-size: 30px;
    font-weight: 100;
    color: rgba(0, 0, 0, 0.7);
    transition: font-weight 0.1s ease;
}

/* Make the category title bold on hover */
.categorias__title-text:hover {
    font-weight: bold;
}

/* Highlight the selected category title */
.categorias__title-text.selected {
    font-weight: bold;
    color: black;
}

.categorias__title-text {
    flex: 1;
}

.categorias__title span {
    margin-right: 10px;
}

.categorias_list {
    height: 46px;
    display: flex;
    align-items: end;
    margin: 0px 30px;
    border-bottom: black 1px solid;
    width: 90%;
}

.categorias_list td {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    transition: all 0.1s ease;
}

/* Make the category list items bold on hover */
.categorias_list td:hover {
    font-weight: bold;
}

.sub_categorias__table {
    margin-top: 30px;
    width: 321px;
    position: absolute;
    z-index: 50;
    left: 445px;
    user-select: none;
}

.sub_categorias__title {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
    padding-left: 10px;
    padding-top: 10px;
    font-size: 20px;
    border-bottom: 1px solid black;
    display: inline-block;
    width: 100%;
    transition: font-weight 0.1s ease;
}

/* Make the subcategory title bold on hover */
.sub_categorias__title:hover {
    font-weight: bold;
}

/* Keep the selected category or subcategory bold */
.sub_categorias__title.selected, 
.sub_categoria_list_item.selected {
    font-weight: bold;
    color: black;
}

.sub_categorias_list {
    max-width: 321px;
}

.sub_categoria_list_item {
    display: block;
    height: 30px;
    color: black;
    background-color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.1s ease;
}

/* Make the subcategory list items bold on hover */
.sub_categoria_list_item:hover {
    font-weight: bold;
}

.categorias__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    font-size: 30px;
    font-weight: 100;
    color: rgba(0, 0, 0, 0.7);
    transition: font-weight 0.1s ease;
}

.categorias__title-text {
    flex: 1;
}

.categorias__title .nuevo-boton {
    background-color: #1aa29b;
    color: white;
    font-size: 14px;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
    margin-left: 10px;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: fit-content;
    height: auto;
}

.categorias__title .nuevo-boton:hover {
    background-color: #177c77;
}

.categorias__title .nuevo-boton svg {
    margin-right: 5px;
    font-size: 16px;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    color: #000;
    cursor: pointer;
    padding: 0;
    margin-top: -5px;
}

.close-button:hover {
    color: #f00;
}