.landing {
    position: relative; 
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden; 
}

.landing::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('./../images/GRAFICA-71.png');
    background-size: cover;
    background-position: center;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    z-index: -1; 
}