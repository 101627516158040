.fecha_horario {
    background-image: url('../images/fechahorario_background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    width: 100%;
}


.container {
    padding-top: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;

}

.fecha_horario_seleccion {
    display: flex;
    gap: 6%;
}


.fecha_horario_botones {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 45px;
    /* height: 450px; */
}


.button {
    background-color: white;
    color: rgba(26, 162, 155);
    text-transform: uppercase !important;
    margin: 0;
    border-radius: 40px;
    border: 1px solid rgba(26, 162, 155) !important;
    font-size: 15px;
}

form {
    margin: 0;
    padding: 0;
    height: 100%;
}

.button:hover {
    color: #008f8a;
    background-color: white;
    border: 3px solid !important;
    border-color: #008f8a !important;
}
